<template>
  <div class="fragment">

    <div class="detail-page">
      <div class="detail-page__left">
        <CardLeftBlock
                :backgroundImage="'expense'"
        >
          <template slot="button">
            <h2 class="mt-0">{{$t('breadcrumbs_newOtherIncome.localization_value.value')}}</h2>
            <div class="card-detail-left__date">
              {{ new Date() | moment("DD MMM, YYYY") }}
            </div>
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <CardRightBlock>
          <template slot="header">
            <div class="order-create__head">
              <div class="order-create__head-line">
                <div class="order-create__header-col"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['ex_backTo',])"></div>
                  <div class="order-create__head-back">
                    <LinkBack
                            :value="$t('ex_backTo.localization_value.value')"
                            @click.native="goRouterBack()"
                    />
                  </div>
                </div>
              </div>
              <div class="order-create__head-title"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['breadcrumbs_newOtherIncome',])"></div>
                {{$t('breadcrumbs_newOtherIncome.localization_value.value')}}
              </div>
              <div class="order-create__head-txt mb-0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['otherIncome_PleaseFillOut',])"></div>
                {{$t('otherIncome_PleaseFillOut.localization_value.value')}}
              </div>
            </div>
          </template>

          <template slot="body">
            <div class="order-create__section">
              <StepA
                      :OI="OI"
                      :countries="countries"
                      :incomeTypes="incomeTypes"
              />
            </div>
          </template>
          <template slot="footer">
            <div class="order-create__footer">
              <!--<div class="order-create__footer-link">-->
                <!--<router-link :to="$store.getters.GET_PATHS.incomeOtherIncomesProfitReport"-->
                             <!--class="site-link site-link&#45;&#45;alt white-space-line"-->
                <!--&gt;-->
                  <!--Delete Order-->
                <!--</router-link>-->
              <!--</div>-->

              <div class="order-create__footer-btn ml-auto"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit"
                     @click="editTranslate(['common_create'])"></div>

                <MainButton
                        class="order-create__footer-btn-i"
                        :value="$t('common_create.localization_value.value')"
                        @click.native="$emit('save')"
                />
              </div>
            </div>
          </template>
        </CardRightBlock>
      </div>

    </div>


  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import StepA from "../../components/StepA";

  export default {
    name: "OtherIncomeCreationSection",
    components: {
      StepA,
      CardLeftBlock,
      LinkBack,
      CardRightBlock,
      MainButton,
    },

    mixins: [mixinDetictingMobile],

    props: {
      OI: Object,
      countries: Array,
      incomeTypes: Array,
    },

    data() {
      return {}
    },

    methods: {}
  }
</script>

<style scoped>

  @media (max-width: 550px) {
    .order-create__product-col.position-relative {
      margin-bottom: 30px !important;
    }
  }
</style>
