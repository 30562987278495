<template>
  <NewOtherIncomeUser
    :OI="OI"
    :countries="countries"
    :incomeTypes="incomeTypes"
    @save="save"
  />
</template>

<script>
  import NewOtherIncomeUser from "./OtherIncomeCreationSection/OtherIncomeCreationSection";
  import {OtherIncome} from "../../models/OtherIncome";
  import {otherIncomeMixin} from "../../../../../mixins/otherIncomeMixins/otherIncomeMixin";
  import {EXPENSES_SYSTEM_TYPES} from "../../../../../staticData/staticVariables";

  export default {
    name: "OtherIncomeCreation",
    components: {
      NewOtherIncomeUser,
    },

    mixins: [otherIncomeMixin],

    created() {
      this.$store.dispatch('getOtherIncomeCreate').then(response => {

        let respData = this.getRespData(response)
        this.countries = respData.countries
        this.incomeTypes = respData.expense_type_instances[EXPENSES_SYSTEM_TYPES.DEFAULT_INCOME_TYPE.value]

        console.log(123, response, this.incomeTypes);
      })
    },

    data() {
      return {
        OI: new OtherIncome(),
        warehouses: [],
        countries: [],
        incomeTypes: [],
        banks: [],
        payees: [],
      }
    },

    methods: {

    }

  }
</script>

<style scoped>

</style>
