<template>
  <div class="fragment">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['otherIncome_OtherIncomeDetails'])"></div>
      {{$t('otherIncome_OtherIncomeDetails.localization_value.value')}}
    </div>

    <!--<div class="order-create__product custom-row">-->
      <!--<div class="order-create__product-col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
           <!--v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">-->
        <!--<div class="admin-edit" @click="editTranslate(['expenses_ExpenseName'])"></div>-->
        <!--<DefaultInput-->
                <!--v-bind:class="{'ui-no-valid': EX.validation.name}"-->
                <!--:error="EX.validation.name"-->
                <!--:errorTxt="$t(`${EX.validationTranslate.name}.localization_value.value`)"-->
                <!--:label="$t('expenses_ExpenseName.localization_value.value')"-->
                <!--:type="'text'"-->
                <!--v-model="EX.data.name"-->
        <!--/>-->
      <!--</div>-->
    <!--</div>-->

    <div class="order-create__product custom-row">
      <div class="order-create__product-col custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['expenses_DocumentDate'])"></div>
        <DatePickerDefault
                :label="$t('expenses_DocumentDate.localization_value.value')"
                :value="OI.data.documentDate"
                v-bind:class="{'ui-no-valid': OI.validation.documentDate}"
                :errorTxt="$t(`${OI.validationTranslate.documentDate}.localization_value.value`)"
                :error="OI.validation.documentDate"
        >
          <template slot="body">
            <date-picker
                    v-model="OI.data.documentDate"
                    valueType="format"
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="custom-col custom-col--50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
      'common_country',
      ])"></div>
        <DefaultSelect
            :options="countries"
            :optionsLabel="'name'"
            :label="$t('common_country.localization_value.value')"
            v-bind:class="{'ui-no-valid': OI.validation.country}"
            :errorTxt="$t(`${OI.validationTranslate.country}.localization_value.value`)"
            :error="OI.validation.country"
            :otherValue="'currentTranslation'"
            @change="(val) => {OI.setCountry(val)}"
            :selected="OI.data.country"
        />
      </div>
    </div>

    <div class="order-create__product custom-row">

      <div class="order-create__product-col custom-col custom-col--50 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['otherIncome_typeOfIncome'])"></div>
        <DefaultSelect
            :options="incomeTypes"
            :label="$t('otherIncome_typeOfIncome.localization_value.value')"
            :optionsLabel="'name'"
            v-bind:class="{'ui-no-valid': OI.validation.type}"
            :errorTxt="$t(`${OI.validationTranslate.type}.localization_value.value`)"
            :error="OI.validation.type"
            :selected="OI.data.type"
            @change="changeIncomeType"
        />
      </div>

      <div class="order-create__product-col custom-col custom-col--16 custom-col--md-33  custom-col--sm-50"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['otherIncome_Total'])"></div>
        <InputSum
                v-bind:class="{'ui-no-valid': OI.validation.total}"
                :error="OI.validation.total"
                :errorTxt="$t(`${OI.validationTranslate.total}.localization_value.value`)"
                :label="$t('otherIncome_Total.localization_value.value')"
                :placeholder="'0'"
                :icoType="'dollar'"
                v-model="OI.data.total"
        />
      </div>
    </div>


    <div class="order-create__product custom-row">
      <div class="custom-col custom-col--75 custom-col--md-100"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['otherIncome_comment'])"></div>
        <TextareaDefault
                :label="$t('otherIncome_comment.localization_value.value')"
                v-bind:class="{'ui-no-valid': OI.validation.comment}"
                :error="OI.validation.comment"
                :errorTxt="$t(`${OI.validationTranslate.comment}.localization_value.value`)"
                v-model="OI.data.comment"
        />
      </div>
    </div>


    <div class="section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['expenses_Documents'])"></div>
      {{$t('expenses_Documents.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row"
         :key="images - 100"
    >
      <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
           v-for="(item, index) in OI.data.Files.data.downloadFiles"
           :key="index">
        <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item)">
                <CloseIcon/>
              </span>
          <div class="site-document__img">
            <img v-if="item.mime_type !== 'application/pdf'"
                 :src="`data:${item.type};base64,` + item.base64"
                 @click="showSingle(item.id + 1)"
                 alt="img"
            >
            <img v-else
                 @click="openPdf(item['base64'])"
                 style="width: 30px; height: 40px;"
                 src="/img/common/pdf_file_icon.svg"
                 alt="ico"
            >
          </div>
        </div>
      </div>

      <div class="order-create__col custom-col position-relative"
           v-if="OI.data.Files.data.maxCountFiles - Object.keys(OI.data.Files.data.downloadFiles).length > 0"
      >
        <DropZone class="drop-zone-bg"
                  :parentFiles="OI.data.Files.data.files"
                  :multiple="true"
                  :maxCount="OI.data.Files.data.maxCountFiles - OI.data.Files.data.downloadFiles.length"
                  :maxSize="OI.data.Files.data.maxSizeFiles"
                  :accept="'image/x-png,image/jpeg,'"
                  @changeImg="changeImg"
        />
        <div class="error-field ml-3" v-if="OI.data.Files.validation.files">
          {{$t('common_fileIsRequired.localization_value.value')}}
        </div>
      </div>
    </div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>

<!--    <div class="custom-row product-img-row"-->
<!--         v-if="Object.keys(OI.data.Files.data.downloadFiles).length > 0"-->
<!--         :key="images"-->
<!--    >-->
<!--      <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100"-->
<!--           v-for="(file, indexFile) in OI.data.Files.data.downloadFiles"-->
<!--           :key="indexFile"-->
<!--      >-->
<!--        <div class="site-document d-flex justify-content-center">-->
<!--          <div class="site-document__remove" @click="removeDocument(parseInt(_.first(Object.keys(file))) + 1)">-->
<!--            <CloseIcon/>-->
<!--          </div>-->
<!--          <div class="site-document__img">-->
<!--            <img :src="'data:image/png;base64,' + file[_.first(Object.keys(file))]"-->
<!--                 @click="showSingle(_.first(Object.keys(file)))" alt="img">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
  </div>
</template>

<script>
  import InputSum from "../../../../UI/inputs/InputSum/InputSum";
  import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import DropZone from "../../../../coreComponents/DropZone/DropZone";
  import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import CloseIcon from '../../../../../../public/img/modal-group/close-icon.svg?inline'
  // import {EXPENSES_SYSTEM_TYPES} from "../../../../../staticData/staticVariables";
  import DatePickerDefault from "../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import Lightbox from "vue-easy-lightbox";

  export default {
    name: "StepA",
    components: {
      DatePickerDefault,
      DatePicker,
      DefaultSelect,
      DropZone,
      TextareaDefault,
      InputSum,
      CloseIcon,
      Lightbox,
    },

    props: {
      OI: Object,
      countries: Array,
      incomeTypes: Array,
    },

    data() {
      return {
        images: -1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, // default: 0
      }
    },

    mounted() {

    },

    methods: {
      changeImg(files, maxSizeError) {
        this.OI.data.Files.setFiles(files)

        this.OI.data.Files.setFilesMaxSizeError(maxSizeError)
      },

      changeIncomeType(val){
        this.OI.setType(val)
      },

      removeDocument(item) {
        let targetFile = item.id

        //delete big image
        if (item.type === 'small_image') {
          targetFile = item.id + 1
        }

        this.$store.dispatch('removeUserDocument', targetFile).then(() => {

          let files = this.OI.data.Files
          let updatedFiles = this._.remove(files.data.downloadFiles, function(n) {
            return n.id === item.id
          })
          files.getDownloadFiles(updatedFiles)
          this.images = item.id

        })
      },

      show() {
        this.visible = true
      },

      handleHide() {
        this.visible = false
      },

      showSingle(id) {
        this.$store.dispatch('getFileFromServer', id).then((response) => {
          this.imgs = []
          console.log(response);
          this.imgs.push({
            title: 'img',
            src: `data:image/png;base64,` + response[id]
          })
          this.show()
        })
      },

      openPdf(base64) {
        this.globalOpenPdfBase64(base64)
      },
    },


  }
</script>

<style scoped>

</style>
